import axios from "~/plugins/axios";
import { defaultParams } from "./defaultParams";

export default {
  search(parameters, facets) {
    // var parameters = {
    //   page: pageNumber,
    //   page_size: pageSize,
    //   timeslotId: 0,
    //   warehouseId: 0,
    //   catalogRestrictionId: 0,
    //   deliveryWarehouseId: 0
    // };
    // if (categoryId) parameters["parent_category_id"] = categoryId;
    // if (search) parameters["q"] = search;
    for (var parameterName in facets) {
      var value = facets[parameterName];
      if (value && value.length > 0) {
        // parameters[parameterName] = facets[parameterName].reduce(
        //   (f, s) => `${f},${s}`
        // );
        parameters[parameterName] = facets[parameterName];
      }
    }

    // if (sort) parameters["sort"] = sort;
    // if (storeId) parameters["store_id"] = storeId;

    return axios
      .get("/ebsn/api/products", {
        params: { ...parameters, ...defaultParams() }
      })
      .then(response => response.data.data)
      .catch(error => error);
  },
  suggest(text) {
    let parameters = { q: text };
    return axios

      .get("/ebsn/api/products/suggest", {
        params: { ...parameters, ...defaultParams() }
      })
      .then(response => {
        let items = response.data.data.items;
        return items;
      })
      .catch(() => {
        return [];
      });
  },
  getProductBySlug(slug) {
    return axios
      .get("/ebsn/api/products", { params: { slug: slug, ...defaultParams() } })
      .then(response => response.data.data)
      .catch(error => {
        console.log(error);
        return null;
      });
  },
  getProductsByCategoryId(parentCategoryId) {
    return axios
      .get("/ebsn/api/products", {
        params: { parent_category_id: parentCategoryId }
      })
      .then(response => response.data.data)
      .catch(error => error);
  },
  getProductsByIds(productIds) {
    return axios
      .get("/ebsn/api/products", { params: { product_id: productIds } })
      .then(response => response.data.data)
      .catch(error => error);
  },
  adv(parameters) {
    return axios
      .get("/ebsn/api/adv", { params: { ...parameters, ...defaultParams() } })
      .then(response => {
        let items = response.data.data;
        return items;
      })
      .catch(error => error);
  },
  getProductPrices(productId) {
    return axios
      .get("/ebsn/api/product-price/get-prices", {
        params: { product_id: productId }
      })
      .then(response => {
        let items = response.data.data;
        return items;
      })
      .catch(error => error);
  },
  getLastOrder(parameters) {
    return axios
      .get("/ebsn/api/products", {
        params: { ...parameters, ...defaultParams() }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => error);
  }
};
