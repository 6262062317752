<template>
  <div class="buttons-slider" v-if="proposals.length > 0">
    <swiper :options="swiperOption" ref="swiperNavbarRef">
      <swiper-slide
        v-for="(link, index) in proposals"
        v-bind:key="index"
        :ref="swiperNavbarRef"
      >
        <ProposalButtonLink :proposal="link" />
      </swiper-slide>
    </swiper>
  </div>
</template>
<style scoped lang="scss">
.swiper-slide {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: auto;
  }
}
</style>
<script>
import ProposalButtonLink from "@/components/proposal/ProposalButtonLink.vue";

export default {
  name: "ProposalButtonsGrid",
  props: {
    proposals: { type: Array, required: true }
  },
  components: {
    ProposalButtonLink
  },
  data() {
    return {
      swiperNavbarRef: null,
      paginationClass: "buttons-navbar",
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        // centeredSlides: true,
        // watchOverflow: true,
        autoplay: this.$vuetify.breakpoint.smAndDown ? true : false,
        // loop: this.$vuetify.breakpoint.smAndDown ? true : false,
        observer: true,
        observeParents: true,
        pagination: {
          el: `.slider-navbar-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `.slider-navbar-prev-${this.paginationClass}`,
          nextEl: `.slider-navbar-next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: 1
          },
          600: {
            slidesPerView: 1.5
          },
          960: {
            slidesPerView: "auto"
          },
          1264: {
            slidesPerView: "auto"
          },
          1904: {
            slidesPerView: "auto"
          }
        }
      }
    };
  }
};
</script>
