<template>
  <div class="d-flex flex-row justify-end">
    <!-- v-show="!isListening" -->
    <v-combobox
      v-show="showSearchInput"
      class="search-input"
      background-color="grey lighten-1"
      clearable
      solo
      flat
      dense
      :height="isCordova ? '48' : '40'"
      v-model="selected"
      ref="autocomplete"
      :items="getItems"
      :search-input.sync="search"
      no-filter
      :label="$t('navbar.searchPlaceholder')"
      :placeholder="$t('navbar.searchPlaceholder')"
      item-text="name"
      item-value="name"
      item-color="accent"
      hide-details
      hide-no-data
      return-object
      type="search"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter.native.prevent.stop="doSearch"
      :menu-props="{ closeOnContentClick: true, contentClass: 'searches-menu' }"
    >
      <!-- :menu-props="menuProps" -->
      <!-- :auto-select-first="true" -->
      <!-- cache-items -->
      <!-- :item-text="itemLabel" -->

      <template v-slot:item="{ item }" class="search-item">
        <v-list-item-icon class="search-icon">
          <img v-if="item.type == 'Categorie'" :src="getImage(item)" />
          <v-icon
            v-else-if="item.type == 'Ricerche'"
            @click.prevent.stop="deleteWord(item)"
            small
          >
            $close
          </v-icon>
          <v-icon v-else-if="item.type == 'FastSearch'" color="accent"
            >$fastSearch</v-icon
          >
          <v-icon v-else small>$search</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          @click="
            item.type == 'FastSearch' ? openFastSearchDialog() : doSearch(item)
          "
        >
          <v-list-item-title>
            <span
              class="font-weight-bold accent--text text-body-2"
              v-if="item.type == 'FastSearch'"
            >
              {{ $t("fastSearch.searchInput.label") }}
            </span>
            <text-highlight v-else :queries="search ? search : '*'">
              {{ item.name }}
            </text-highlight>
          </v-list-item-title>
          <v-list-item-subtitle v-if="item.type == 'Categorie'">
            Cerca <strong>{{ search }}</strong> in {{ item.name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.type == 'Marche'">
            Cerca <strong>{{ search }}</strong> con marca {{ item.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <div class="badge" v-if="item.count">
            {{ item.count }}
          </div>
        </v-list-item-action>
      </template>
      <template slot="append">
        <Barcode v-if="isCordova" @input="doSearch" />
        <v-btn v-else depressed color="secondary" @click="doSearch"
          ><v-icon>$search</v-icon></v-btn
        >
      </template>
    </v-combobox>

    <div v-if="isCordova" class="speech-reco-container d-flex align-center">
      <transition
        :name="slidingAnimation ? 'slide-fade' : 'fade'"
        v-on:after-leave="afterLeave"
      >
        <div v-if="isListening" class="slide-to-cancel">
          <v-icon class="red-lighting">$microphone</v-icon>
          <v-icon>$prev</v-icon>
          Scorri per annullare
        </div>
      </transition>
      <v-tooltip
        bottom
        class="microphone-btn sliding-button"
        :content-class="
          tooltipDisabled
            ? 'tooltip-content-container hide'
            : 'tooltip-content-container'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-0 ml-sm-3"
            :class="{ 'no-border': $vuetify.breakpoint.xs }"
            :large="!$vuetify.breakpoint.xs"
            icon
            retain-focus-on-click
            @click="tooltipDisabled = false"
            v-long-press="300"
            @long-press-start="onLongPressStart"
            @long-press-stop="onLongPressStop()"
            v-touch="{
              left: () => cancelSpeechRecognition()
            }"
          >
            <v-icon color="primary">$microphone</v-icon>
          </v-btn>
        </template>
        <div class="tooltip-content" :class="tooltipDisabled ? 'd-none' : ''">
          <span v-if="!isListening">
            Tieni premuto per attivare la ricerca vocale
            <br />
            Rilascia per iniziare la ricerca
          </span>
          <span v-else>Stai cercando: {{ speechResult }}</span>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>
<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.01s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-1000px);
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.sliding-button {
  width: 50px;
  height: 50px;
  position: relative;
  user-select: none;
  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: green;
    z-index: 100;
    //     Set the transition animation
    transition: 0.3s;
  }
  .remove {
    position: absolute;
    width: 30px;
    height: 30px;
    background: red;
    right: 0;
    top: 0;
    color: #fff;
    text-align: center;
    font-size: 40px;
  }
}
.v-tooltip__content.v-tooltip__content--fixed.tooltip-content-container {
  width: 88%;
  right: 10px;
  left: auto;
  max-width: 350px;
  &.hide {
    display: none;
  }
}

.slide-to-cancel {
  white-space: nowrap;
}

.v-autocomplete.search-input {
  font-size: $font-size-root;
  ::placeholder {
    font-size: $font-size-root !important;
  }
  .v-input__slot {
    padding-right: 0px !important;
    .v-input__append-inner {
      .v-btn {
        height: 40px;
        min-width: 40px;
        width: 40px;
        .v-icon {
          font-size: 18px;
        }
        .icon-qr-code {
          font-size: 24px;
        }
      }
    }
    .v-label {
      top: calc(50% - 9px) !important;
    }
  }
}
.search-input.v-select.v-select--is-menu-active {
  .v-input__control {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.v-autocomplete__content {
  .v-list--dense {
    .v-subheader {
      // background-color: var(--v-grey-lighten3);
      font-size: 14px;
      font-weight: bold;
    }
    .v-list-item {
      min-height: 34px;
      .v-list-item__icon {
        padding-top: 4px;
        padding-bottom: 4px;
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }
}
.v-icon.v-icon.red-lighting {
  color: $primary;
  animation: opacity-change 1.15s ease-in-out infinite;
}
@keyframes opacity-change {
  100% {
    opacity: 0;
  }
}
.search-icon {
  img {
    width: 30px;
    height: 30px;
  }
}
.searches-menu.v-autocomplete__content.v-menu__content {
  max-height: calc(100vh - 190px) !important;
}
</style>
<script>
import Barcode from "./Barcode.vue";
import FastSearchMenu from "./FastSearchMenu.vue";

import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";

import { mapGetters, mapActions } from "vuex";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";

import LongPress from "vue-directive-long-press";

export default {
  components: {
    Barcode
  },
  data() {
    return {
      showTooltip: true,
      showSearchInput: true,
      showSpeechReco: true,
      slidingAnimation: false,
      startX: 0, // Touch the location
      endX: 0, // End position
      moveX: 0, // Position when sliding
      disX: 0, // Moving distance
      deleteSlider: "", // The effect of sliding , Use v-bind:style="deleteSlider"
      speechResult: null,
      loading: false,
      isFocused: null,
      selected: null,
      search: null,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      },
      isListening: false,
      tooltipDisabled: false,
      speechOptions: {
        language: "it-IT",
        matches: "5",
        prompt: "", // Android only
        showPopup: false, // Android only
        showPartial: true
      }
    };
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.fetchSuggestion();
      }
    }
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    getItems() {
      let result = this.search ? this.items : this.commonSearch;
      result.unshift({
        type: "FastSearch",
        name: "Spesa veloce"
      });
      return result;
      // return this.search ? this.items : this.commonSearch;
    }
  },
  directives: {
    "long-press": LongPress
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    afterLeave: function(el) {
      console.log("afterLeave", el);
      this.showSearchInput = true;
    },
    getImage(item) {
      return "/search-category/" + item.id + ".png";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    fetchSuggestion: debounce(async function() {
      let _this = this;
      this.loading = true;
      if (this.search) {
        await ProductService.suggest(this.search.trim()).then(function(
          response
        ) {
          let newItems = [];
          let prevType = "none";

          forEach(response, function(value) {
            if (value.type != prevType) {
              newItems.push({ header: value.type });
              prevType = value.type;
            }
            newItems.push(value);
          });
          _this.items = newItems;
          _this.loading = false;
        });
      }
    }, 200),
    openFastSearchDialog() {
      this.$emit("closeSearchModal", true);
      console.log("selected", this.selected);
      var defaultConfig = {
        toolbarColor: "secondary",
        fullscreen: this.$vuetify.breakpoint.smAndDown
      };
      let properties = {
        waitForResult: true,
        width: 650
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    },
    doSearch(item) {
      if (this.search) {
        if (this.search.length < 3) return;
      }
      console.log("doSearch", item);
      this.items = [];

      var currentQuery = {};
      if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Barcode") {
        currentQuery.barcode = item.name;
      } else if (item.type === "FastSearch") {
        currentQuery.q = "";
        console.log("currentQuery.q", currentQuery.q);
      } else if (this.search != null) {
        currentQuery.q = this.search;
        this.saveWord(currentQuery.q);
      }

      if (currentQuery.q || currentQuery.barcode) {
        AnalyticsService.search(this.search);
        //this.$store.dispatch("category/resetFilters");
        global.EventBus.$emit("resetFilters");
        this.$router.push({
          // path: "/search?q=:q&parent_category_id=:parent_category_id",
          path: "/search",
          name: "Search",
          query: currentQuery
        });

        this.$nextTick(() => {
          this.$refs.autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    },
    enableTooltip(param) {
      console.log("enableTooltip: ", param);
      this.tooltipDisabled = false;
      console.log("tooltipDisabled", this.tooltipDisabled);
    },
    onLongPressStart() {
      // this.isListening = true;
      // triggers after 300ms of mousedown
      this.tooltipDisabled = false;
      this.showSearchInput = false;
      console.log("tooltipDisabled", this.tooltipDisabled);
      this.startSpeechRecognition();
    },
    onLongPressStop(forceStop = false) {
      // triggers on mouseup of document
      let _this = this;
      this.slidingAnimation = forceStop;
      setTimeout(function() {
        _this.isListening = false;
        _this.tooltipDisabled = true;
        console.log("forceStop", forceStop);
        _this.stopSpeechRecognition(forceStop);
      }, 100);
    },
    cancelSpeechRecognition() {
      this.showTooltip = false;
      console.log("cancelSpeechRecognition");
      this.onLongPressStop(true);
    },
    startSpeechRecognition() {
      let _this = this;
      _this.speechResult = null;
      // Verify if recognition is available
      // eslint-disable-next-line no-undef
      // let platform = device ? device.platform.toLowerCase() : "ios";
      window.plugins.speechRecognition.isRecognitionAvailable(
        function(available) {
          if (!available) {
            console.log("Riconoscimento Vocale non disponibile");
          }

          // Check if has permission to use the microphone
          window.plugins.speechRecognition.hasPermission(
            function(isGranted) {
              if (isGranted) {
                _this.isListening = true;
                window.plugins.speechRecognition.startListening(
                  function(result) {
                    // Show results in the console
                    console.log("speect rec result", result);
                    _this.speechResult = result[0];
                  },
                  function(err) {
                    console.error(err);
                  },
                  _this.speechOptions
                );
              } else {
                // Request the permission
                window.plugins.speechRecognition.requestPermission(
                  function() {
                    // Request accepted, start recognition
                    // _this.startListening(_this);
                    console.log(
                      "window.plugins.speechRecognition",
                      window.plugins.speechRecognition
                    );
                    // window.plugins.speechRecognition.startListening(
                    //   function(result) {
                    //     // Show results in the console
                    //     console.log("speect rec result", result);
                    //     _this.speechResult = result[0];
                    //   },
                    //   function(err) {
                    //     console.error(err);
                    //   },
                    //   _this.speechOptions
                    // );
                  },
                  function(err) {
                    console.log(err);
                  }
                );
              }
            },
            function(err) {
              console.log(err);
            }
          );
        },
        function(err) {
          console.log(err);
        }
      );
    },
    startListening(_this) {
      // var _this = this;
      console.log(
        "window.plugins.speechRecognition",
        window.plugins.speechRecognition
      );
      window.plugins.speechRecognition.startListening(
        function(result) {
          // Show results in the console
          console.log("speech rec result", result);
          _this.speechResult = result[0];
        },
        function(err) {
          console.error(err);
        },
        _this.speechOptions
      );
    },
    stopSpeechRecognition(forceStop) {
      console.log("stopSpeechRecognition");
      window.plugins.speechRecognition.stopListening(
        function() {
          // No more recognition
        },
        function(err) {
          console.log(err);
        }
      );

      console.log("this.speechResult", this.speechResult);
      if (this.speechResult && !forceStop) {
        console.log("this.doSearch");
        this.doSearch({ type: "Ricerche", name: this.speechResult });
        this.speechResult = null;

        this.tooltipDisabled = true;
        console.log("tooltipDisabledIfSpeech", this.tooltipDisabled);
      }
    }
  },
  mounted() {
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
  }
};
</script>
