<template>
  <router-link
    class="mx-7 white--text text-uppercase text-decoration-none"
    :to="link"
    @click.prevent="handleLink"
  >
    {{ proposal.descr }}
  </router-link>
</template>
<style scoped lang="scss">
.swiper-slide {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: auto;
  }
}
</style>
<script>
import banner from "~/mixins/banner";

export default {
  name: "ProposalButtonLink",
  mixins: [banner],
  data() {
    return {
      paginationClass: "buttons-navbar",
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true
      }
    };
  }
};
</script>
